import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";
import AudioRecorder from "audio-recorder-polyfill";

// Polyfill MediaRecorder for Safari
if (!window.MediaRecorder) {
  window.MediaRecorder = AudioRecorder;
}

export default class extends Controller {
  static targets = [
    "recordingForm",
    "recordingSongId",
    "recordingLongitude",
    "recordingLatitude",
    "recordingsList",
    "canvas",
    "loading",
  ];

  connect() {
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.recording = false;
    this.consumer = createConsumer();
    this.chunkSize = 64 * 1024; // 64KB chunks
    this.stream = null;
    this.mediaRecorder = null;

    this.initializeVisualizer();
    this.initializeAudio();
    this.startVisualizer();
  }

  async initializeAudio() {
    if (navigator.mediaDevices) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.setupAudioNodes(this.stream);
      } catch (err) {
        this.handleError(err);
      }
    } else {
      this.handleError();
    }
  }

  setupMediaRecorder() {
    if (!this.stream) {
      return false;
    }

    const options = { mimeType: "audio/mp4" };
    try {
      this.mediaRecorder = new MediaRecorder(this.stream, options);
    } catch (e1) {
      try {
        this.mediaRecorder = new MediaRecorder(this.stream);
      } catch (e2) {
        return false;
      }
    }

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0 && this.subscription) {
        this.sendAudioChunk(event.data);
      }
    };

    this.mediaRecorder.onstop = this.handleStop.bind(this);
    return true;
  }

  async sendAudioChunk(blob) {
    const arrayBuffer = await blob.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < uint8Array.length; i += this.chunkSize) {
      const chunk = uint8Array.slice(i, i + this.chunkSize);
      const base64data = btoa(String.fromCharCode.apply(null, chunk));
      this.subscription.send({
        audio_chunk: base64data,
      });
    }
  }

  handleError(err) {
    const message = err
      ? `Error: ${err}`
      : "Oh no! Your browser cannot access your computer's microphone. Please update your browser.";
  }

  handleStop(event) {
    this.loadingTarget.style.display = "block";

    if (this.subscription) {
      this.subscription.send({ end_of_stream: true });
    }
  }

  fetchRecordings() {
    fetch(
      `${this.recordingFormTarget.action}?song_id=${this.recordingSongIdTarget.value}`,
      {
        method: "GET",
        headers: {
          Accept: "text/html",
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    )
      .then((response) => response.text())
      .then((html) => {
        this.loadingTarget.style.display = "none";
        this.recordingsListTarget.innerHTML = html;
        // console.log("Recordings updated dispatch");
        // Dispatch a custom event
        const event = new CustomEvent("recordings:updated", {
          bubbles: true,
          detail: {},
        });
        this.element.dispatchEvent(event);
      })
      .catch((error) => {
        // console.error("Error fetching recordings:", error);
      });
  }

  toggleRecording() {
    if (!this.mediaRecorder && !this.setupMediaRecorder()) {
      return;
    }

    if (this.recording) {
      this.mediaRecorder.stop();
      this.recording = false;
      this.canvas.classList.remove("recording");
      this.canvas.classList.add("disabled");
    } else {
      this.initializeActionCable();
      this.mediaRecorder.start(1000);
      this.recording = true;
      this.canvas.classList.remove("disabled");
      this.canvas.classList.add("recording");
    }
    this.startVisualizer();
  }

  initializeActionCable() {
    this.subscription = this.consumer.subscriptions.create(
      {
        channel: "AudioChannel",
        song_id: this.recordingSongIdTarget.value,
        longitude: this.recordingLongitudeTarget.value,
        latitude: this.recordingLatitudeTarget.value,
      },
      {
        connected: () => {
          // console.log("Connected to AudioChannel");
        },
        disconnected: () => {
          // console.log("Disconnected from AudioChannel");
        },
        received: (data) => {
          if (data.status === "completed") {
            this.handleRecordingCompleted();
          }
        },
      }
    );
  }

  handleRecordingCompleted() {
    this.fetchRecordings();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  disconnect() {
    this.isVisualizerRunning = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }

  initializeVisualizer() {
    this.canvas = this.canvasTarget;
    this.canvasCtx = this.canvas.getContext("2d");
    this.analyser = this.audioCtx.createAnalyser();
    this.analyser.fftSize = 2048;
    this.canvas.classList.add("disabled");
  }

  setupAudioNodes(stream) {
    const source = this.audioCtx.createMediaStreamSource(stream);
    source.connect(this.analyser);
    this.visualize();
  }

  startVisualizer() {
    if (!this.isVisualizerRunning) {
      this.isVisualizerRunning = true;
      this.visualize();
    }
  }

  async resumeAudioContext() {
    if (this.audioCtx.state === "suspended") {
      await this.audioCtx.resume();
    }
  }

  visualize() {
    const bufferLength = this.analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = async () => {
      if (!this.isVisualizerRunning) return;

      await this.resumeAudioContext();

      requestAnimationFrame(draw);

      const WIDTH = this.canvas.width;
      const HEIGHT = this.canvas.height;

      this.canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

      this.analyser.getByteTimeDomainData(dataArray);

      this.canvasCtx.lineWidth = 2;
      this.canvasCtx.strokeStyle = this.recording
        ? "rgb(255, 100, 100)"
        : "rgb(150, 150, 150)";
      this.canvasCtx.beginPath();

      const sliceWidth = (WIDTH * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * HEIGHT) / 2;

        if (i === 0) {
          this.canvasCtx.moveTo(x, y);
        } else {
          this.canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      this.canvasCtx.lineTo(WIDTH, HEIGHT / 2);
      this.canvasCtx.stroke();
    };

    draw();
  }
}
